#colorbox, #cboxOverlay, #cboxWrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1040;
  -webkit-transform: translate3d(0, 0, 0);
}

#cboxWrapper {
  max-width: none;
}

#cboxOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
}

#cboxMiddleLeft, #cboxBottomLeft {
  clear: left;
}

#cboxContent {
  position: relative;
}

#cboxLoadedContent {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

#cboxTitle {
  margin: 0;
}

#cboxLoadingOverlay, #cboxLoadingGraphic {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#cboxPrevious, #cboxNext, #cboxClose, #cboxSlideshow {
  cursor: pointer;
  color: white;
}

.cboxPhoto {
  float: left;
  margin: auto;
  border: 0;
  display: block;
  max-width: none;
  -ms-interpolation-mode: bicubic;
}

.cboxIframe {
  width: 100%;
  height: 100%;
  display: block;
  border: 0;
  padding: 0;
  margin: 0;
}

#colorbox, #cboxContent, #cboxLoadedContent {
  box-sizing: content-box;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
}

#cboxOverlay {
  background: #000;
  opacity: 0.9;
  filter: alpha(opacity=90);
}

#colorbox {
  outline: 0;
}

#cboxContent {
  margin: 50px 50px 0;
  padding-bottom: 80px;
}

.cboxIframe {
  background: #fff;
}

#cboxError {
  padding: 50px;
  border: 1px solid #ccc;
}

#cboxLoadedContent {
  border: 5px solid #000;
  background: #fff;
}

#cboxTitle {
  color: #ccc;
}

#cboxCurrent {
  position: absolute;
  top: -20px;
  left: 0;
  color: #ccc;
}


#cboxPrevious, #cboxNext, #cboxSlideshow, #cboxClose {
  border: 0;
  padding: 0;
  margin: 0;
  overflow: visible;
  width: auto;
}

#cboxPrevious:active, #cboxNext:active, #cboxSlideshow:active, #cboxClose:active {
  outline: 0;
}

#cboxSlideshow {
  position: absolute;
  top: -20px;
  right: 90px;
  color: #fff;
}

#cboxPrevious {
  position: absolute;
  top: 50%;
  left: -2.5rem;
  font-size: 3rem;
  transform: translateY(-50%);
}

#cboxNext {
  position: absolute;
  top: 50%;
  right: -2.5rem;
  font-size: 3rem;
  transform: translateY(-50%);
}

#cboxClose {
  position: absolute;
  top: -3rem;
  right: -2rem;
  display: block;
  font-size: 3rem;
  line-height: 1;
}

#cboxClose, #cboxNext, #cboxPrevious {
  border: 1px solid transparent;
  background-color: transparent
}